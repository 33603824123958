<template>
  <footer class="mt-5" v-bind:style="{ backgroundImage: `url(${publicPath}/assets/${data.portfolioName}/banner.jpg)` }">
    <div class="container">
      <div class="row py-4">
        <div class="col-12 text-white">
          <div class="row">
            <h6 class="col-12">{{ data.json.title }}</h6>
            <a href="tel:087 0000000" class="col-12 text-primary">
              <b-icon icon="telephone" aria-hidden="true" class="text-white"></b-icon>
              <span class="pl-3">{{ data.global['phoneNumber'] }}</span>
            </a>
            <a href="mailto:info@example.ie" class="col-12 text-primary">
              <b-icon icon="envelope" aria-hidden="true" class="text-white"></b-icon>
              <span class="pl-3">{{ data.global['email'] }}</span>
            </a>
          </div>
          <div class="row pt-0">
            <div v-for="(location, index) in data.json.locations" :key="index" class="col-12 col-sm-6">
              <address v-html="location.address" class="mb-0"></address>
              <div class="col-2 pl-0 pb-2">
                <a :href="location['mapLink']" target="_blank" class="text-white">
                  <img class="img-fluid" src="../assets/mapLogo.png" alt="Google Maps" title="Google Maps" style="max-width: 50px;"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="pl-2 social">
          <b-button v-for="(platform, index) in data.social" :key="index" :title="platform.title" variant="primary" size="sm" :href="platform.href" target="_blank" class="m-2">
            <b-icon :icon="platform.icon" aria-hidden="true" class="text-white"></b-icon>
          </b-button>
        </div>

      </div>
      <div class="row p-3 copyright">
        <img v-if="data.json['logo'] !== ''" :src="`${publicPath}assets/${data.portfolioName}/${data.json['logo']}`" alt="Company Logo" class="col-12 img-fluid rounded pb-2" style="max-width:200px;margin: auto;">
        <small class="text-white col-12">Copyright &#169; <span id="copyright">{{data.year}}</span> All Rights Reserved</small>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'Footer');
      const portfolioName = this.$portfolioName;
      return {
        json: cJson,
        portfolioName: portfolioName,
        global: this.$webData.global,
        social: this.$webData.social,
        year: new Date().getFullYear()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  footer {
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.7);
    }
    address {
      font-weight: 300;
    }
    b-icon {
      font-size: 0.5em;
    }
    .social {
      position: relative;
    }
    .copyright {
      position: relative;
      border-top: 2px solid $primary;
      small {
        text-align: center;
      }
    }
  }
</style>
